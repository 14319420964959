@import "color";

@mixin font-weight-lighter {
  font-weight: 350;
}

@mixin font-weight-light {
  font-weight: 400;
}

@mixin font-weight-medium {
  font-weight: 500;
}

@mixin font-weight-bold {
  font-weight: 700;
}

@mixin typo-base {
  font-family: 'NotoSansKR';
  color: $ss-gray-100;
}

@mixin h1 {
  @include typo-base;
  @include font-weight-medium;
  font-size: 96px;
  line-height: 134px;
  letter-spacing: -0.015em;
}

@mixin h2 {
  @include typo-base;
  @include font-weight-light;
  font-size: 58px;
  line-height: 76px;
  letter-spacing: -0.005em;
}

@mixin h3 {
  @include typo-base;
  @include font-weight-light;
  font-size: 47px;
  line-height: 62px;
}

@mixin h4-landing {
  @include typo-base;
  @include font-weight-medium;
  font-size: 33px;
  line-height: 48px;
  letter-spacing: 0.0025em;
}

@mixin h4 {
  @include typo-base;
  @include font-weight-light;
  font-size: 33px;
  line-height: 48px;
  letter-spacing: 0.0025em;
}
@mixin h4-m {
  @include h4;
  @include font-weight-medium;
}

@mixin h5 {
  @include typo-base;
  @include font-weight-light;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.0025em;
}

@mixin h6 {
  @include typo-base;
  @include font-weight-light;
  font-size: 23px;
  line-height: 34px;
}

@mixin h7 {
  @include typo-base;
  @include font-weight-light;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.0015em;
}

@mixin h7-m {
  @include h7;
  @include font-weight-medium;
}

@mixin subtitle-text-1 {
  @include typo-base;
  @include font-weight-medium;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0015em;
}

@mixin subtitle-text-2 {
  @include typo-base;
  @include font-weight-medium;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
}

@mixin subtitle-text-2-landing {
  @include typo-base;
  @include font-weight-light;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.005em;
}

@mixin body-text-1 {
  @include typo-base;
  @include font-weight-light;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.005em;
}

@mixin body-text-1-m {
  @include body-text-1;
  @include font-weight-medium;
}

@mixin body-text-2 {
  @include typo-base;
  @include font-weight-light;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.0025em;
}

@mixin body-text-2-m {
  @include body-text-2;
  @include font-weight-medium;
}

@mixin body-text-3 {
  @include typo-base;
  @include font-weight-light;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
}

@mixin body-text-4 {
  @include typo-base;
  @include font-weight-light;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.0025em;
}

@mixin body-text-4-m {
  @include body-text-4;
  @include font-weight-medium;
}

@mixin body-text-5 {
  @include typo-base;
  @include font-weight-light;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.0025em;
}

@mixin body-text-5-m {
  @include body-text-4;
  @include font-weight-medium;
}

@mixin content-text-1 {
  @include typo-base;
  @include font-weight-light;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.0025em;
}

@mixin content-text-2 {
  @include typo-base;
  @include font-weight-lighter;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: $ss-gray-90;
}

@mixin btn-text-1 {
  @include typo-base;
  @include font-weight-medium;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.0015em;
}

@mixin btn-text-2 {
  @include typo-base;
  @include font-weight-medium;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.005em;
}

@mixin btn-text-3 {
  @include typo-base;
  @include font-weight-medium;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
}

@mixin btn-text-4 {
  @include typo-base;
  @include font-weight-medium;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.005em;
}

@mixin btn-text-5 {
  @include typo-base;
  @include font-weight-medium;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.005em;
}


@mixin caption-text-1 {
  @include typo-base;
  @include font-weight-light;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.004em;
}

@mixin caption-text-2 {
  @include typo-base;
  @include font-weight-light;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.015em;
}

@mixin caption-text-3 {
  @include typo-base;
  @include font-weight-light;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.004em;
}