@font-face {
    font-family: 'NotoSansKR';
    font-weight: 700;
    src:
        url('../asset/fonts/NotoSansKR-Bold.woff2') format('woff2'),
        url('../asset/fonts/NotoSansKR-Bold.woff') format('woff'),
        url('../asset/fonts/NotoSansKR-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'NotoSansKR';
    font-weight: 500;
    src:
        url('../asset/fonts/NotoSansKR-Medium.woff2') format('woff2'),
        url('../asset/fonts/NotoSansKR-Medium.woff') format('woff'),
        url('../asset/fonts/NotoSansKR-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'NotoSansKR';
    font-weight: 400;
    src:
        url('../asset/fonts/NotoSansKR-Regular.woff2') format('woff2'),
        url('../asset/fonts/NotoSansKR-Regular.woff') format('woff'),
        url('../asset/fonts/NotoSansKR-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'NotoSansKR';
    font-weight: 300;
    src:
        url('../asset/fonts/NotoSansKR-Light.woff2') format('woff2'),
        url('../asset/fonts/NotoSansKR-Light.woff') format('woff'),
        url('../asset/fonts/NotoSansKR-Light.otf') format('opentype');
}

@font-face {
    font-family: 'NotoSansKR';
    font-weight: 100;
    src:
        url('../asset/fonts/NotoSansKR-Thin.woff2') format('woff2'),
        url('../asset/fonts/NotoSansKR-Thin.woff') format('woff'),
        url('../asset/fonts/NotoSansKR-Thin.otf') format('opentype');
}

@font-face {
    font-family: 'pass';
    font-style: normal;
    font-weight: 400;
    src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAATsAA8AAAAAB2QAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAcg9+z70dERUYAAAF0AAAAHAAAAB4AJwANT1MvMgAAAZAAAAA/AAAAYH7AkBhjbWFwAAAB0AAAAFkAAAFqZowMx2N2dCAAAAIsAAAABAAAAAQAIgKIZ2FzcAAAAjAAAAAIAAAACAAAABBnbHlmAAACOAAAALkAAAE0MwNYJ2hlYWQAAAL0AAAAMAAAADYPA2KgaGhlYQAAAyQAAAAeAAAAJAU+ATJobXR4AAADRAAAABwAAAAcCPoA6mxvY2EAAANgAAAAEAAAABAA5gFMbWF4cAAAA3AAAAAaAAAAIAAKAE9uYW1lAAADjAAAARYAAAIgB4hZ03Bvc3QAAASkAAAAPgAAAE5Ojr8ld2ViZgAABOQAAAAGAAAABuK7WtIAAAABAAAAANXulPUAAAAA1viLwQAAAADW+JM4eNpjYGRgYOABYjEgZmJgBEI2IGYB8xgAA+AANXjaY2BifMg4gYGVgYVBAwOeYEAFjMgcp8yiFAYHBl7VP8wx/94wpDDHMIoo2DP8B8kx2TLHACkFBkYA8/IL3QB42mNgYGBmgGAZBkYGEEgB8hjBfBYGDyDNx8DBwMTABmTxMigoKKmeV/3z/z9YJTKf8f/X/4/vP7pldosLag4SYATqhgkyMgEJJnQFECcMOGChndEAfOwRuAAAAAAiAogAAQAB//8AD3jaY2BiUGJgYDRiWsXAzMDOoLeRkUHfZhM7C8Nbo41srHdsNjEzAZkMG5lBwqwg4U3sbIx/bDYxgsSNBRUF1Y0FlZUYBd6dOcO06m+YElMa0DiGJIZUxjuM9xjkGRhU2djZlJXU1UDQ1MTcDASNjcTFQFBUBGjYEkkVMJCU4gcCKRTeHCk+fn4+KSllsJiUJEhMUgrMUQbZk8bgz/iA8SRR9qzAY087FjEYD2QPDDAzMFgyAwC39TCRAAAAeNpjYGRgYADid/fqneL5bb4yyLMwgMC1H90HIfRkCxDN+IBpFZDiYGAC8QBbSwuceNpjYGRgYI7594aBgcmOAQgYHzAwMqACdgBbWQN0AAABdgAiAAAAAAAAAAABFAAAAj4AYgI+AGYB9AAAAAAAKgAqACoAKgBeAJIAmnjaY2BkYGBgZ1BgYGIAAUYGBNADEQAFQQBaAAB42o2PwUrDQBCGvzVV9GAQDx485exBY1CU3PQgVgIFI9prlVqDwcZNC/oSPoKP4HNUfQLfxYN/NytCe5GwO9/88+/MBAh5I8C0VoAtnYYNa8oaXpAn9RxIP/XcIqLreZENnjwvyfPieVVdXj2H7DHxPJH/2/M7sVn3/MGyOfb8SWjOGv4K2DRdctpkmtqhos+D6ISh4kiUUXDj1Fr3Bc/Oc0vPqec6A8aUyu1cdTaPZvyXyqz6Fm5axC7bxHOv/r/dnbSRXCk7+mpVrOqVtFqdp3NKxaHUgeod9cm40rtrzfrt2OyQa8fppCO9tk7d1x0rpiQcuDuRkjjtkHt16ctbuf/radZY52/PnEcphXpZOcofiEZNcQAAeNpjYGIAg///GBgZsAF2BgZGJkZmBmaGdkYWRla29JzKggxD9tK8TAMDAxc2D0MLU2NjENfI1M0ZACUXCrsAAAABWtLiugAA) format('woff');
}