.flex-box {
  display: flex;
  align-items: center;

  &.baseline {
    align-items: baseline;
  }

  &.start {
    align-items: flex-start;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.content-center {
    justify-content: center;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.gap-8 {
    gap: 8px
  }
  &.gap-16 {
    gap: 16px
  }

  &.inline {
    display: inline-flex;
  }

  &.column {
    flex-direction: column;
  }
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.75rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.mt-5,
.my-5 {
  margin-top: 1.25rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 1.25rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 1.25rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 1.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 1.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 1.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.mt-7,
.my-7 {
  margin-top: 1.75rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 1.75rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 1.75rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.mt-8,
.my-8 {
  margin-top: 2rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 2rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 2rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.mt-9,
.my-9 {
  margin-top: 2.25rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 2.25rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 2.25rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 2.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 2.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 2.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.mt-11,
.my-11 {
  margin-top: 2.75rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 2.75rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 2.75rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.mt-12,
.my-12 {
  margin-top: 3rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 3rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 3rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 3rem !important;
}

.m-13 {
  margin: 3.25rem !important;
}

.mt-13,
.my-13 {
  margin-top: 3.25rem !important;
}

.mr-13,
.mx-13 {
  margin-right: 3.25rem !important;
}

.mb-13,
.my-13 {
  margin-bottom: 3.25rem !important;
}

.ml-13,
.mx-13 {
  margin-left: 3.25rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.mt-14,
.my-14 {
  margin-top: 3.5rem !important;
}

.mr-14,
.mx-14 {
  margin-right: 3.5rem !important;
}

.mb-14,
.my-14 {
  margin-bottom: 3.5rem !important;
}

.ml-14,
.mx-14 {
  margin-left: 3.5rem !important;
}

.m-15 {
  margin: 3.75rem !important;
}

.mt-15,
.my-15 {
  margin-top: 3.75rem !important;
}

.mr-15,
.mx-15 {
  margin-right: 3.75rem !important;
}

.mb-15,
.my-15 {
  margin-bottom: 3.75rem !important;
}

.ml-15,
.mx-15 {
  margin-left: 3.75rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.mt-16,
.my-16 {
  margin-top: 4rem !important;
}

.mr-16,
.mx-16 {
  margin-right: 4rem !important;
}

.mb-16,
.my-16 {
  margin-bottom: 4rem !important;
}

.ml-16,
.mx-16 {
  margin-left: 4rem !important;
}

.m-17 {
  margin: 4.25rem !important;
}

.mt-17,
.my-17 {
  margin-top: 4.25rem !important;
}

.mr-17,
.mx-17 {
  margin-right: 4.25rem !important;
}

.mb-17,
.my-17 {
  margin-bottom: 4.25rem !important;
}

.ml-17,
.mx-17 {
  margin-left: 4.25rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.mt-18,
.my-18 {
  margin-top: 4.5rem !important;
}

.mr-18,
.mx-18 {
  margin-right: 4.5rem !important;
}

.mb-18,
.my-18 {
  margin-bottom: 4.5rem !important;
}

.ml-18,
.mx-18 {
  margin-left: 4.5rem !important;
}

.m-19 {
  margin: 4.75rem !important;
}

.mt-19,
.my-19 {
  margin-top: 4.75rem !important;
}

.mr-19,
.mx-19 {
  margin-right: 4.75rem !important;
}

.mb-19,
.my-19 {
  margin-bottom: 4.75rem !important;
}

.ml-19,
.mx-19 {
  margin-left: 4.75rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.mt-20,
.my-20 {
  margin-top: 5rem !important;
}

.mr-20,
.mx-20 {
  margin-right: 5rem !important;
}

.mb-20,
.my-20 {
  margin-bottom: 5rem !important;
}

.ml-20,
.mx-20 {
  margin-left: 5rem !important;
}

.m-21 {
  margin: 5.25rem !important;
}

.mt-21,
.my-21 {
  margin-top: 5.25rem !important;
}

.mr-21,
.mx-21 {
  margin-right: 5.25rem !important;
}

.mb-21,
.my-21 {
  margin-bottom: 5.25rem !important;
}

.ml-21,
.mx-21 {
  margin-left: 5.25rem !important;
}

.m-22 {
  margin: 5.5rem !important;
}

.mt-22,
.my-22 {
  margin-top: 5.5rem !important;
}

.mr-22,
.mx-22 {
  margin-right: 5.5rem !important;
}

.mb-22,
.my-22 {
  margin-bottom: 5.5rem !important;
}

.ml-22,
.mx-22 {
  margin-left: 5.5rem !important;
}

.m-23 {
  margin: 5.75rem !important;
}

.mt-23,
.my-23 {
  margin-top: 5.75rem !important;
}

.mr-23,
.mx-23 {
  margin-right: 5.75rem !important;
}

.mb-23,
.my-23 {
  margin-bottom: 5.75rem !important;
}

.ml-23,
.mx-23 {
  margin-left: 5.75rem !important;
}

.m-24 {
  margin: 6rem !important;
}

.mt-24,
.my-24 {
  margin-top: 6rem !important;
}

.mr-24,
.mx-24 {
  margin-right: 6rem !important;
}

.mb-24,
.my-24 {
  margin-bottom: 6rem !important;
}

.ml-24,
.mx-24 {
  margin-left: 6rem !important;
}

.m-25 {
  margin: 6.25rem !important;
}

.mt-25,
.my-25 {
  margin-top: 6.25rem !important;
}

.mr-25,
.mx-25 {
  margin-right: 6.25rem !important;
}

.mb-25,
.my-25 {
  margin-bottom: 6.25rem !important;
}

.ml-25,
.mx-25 {
  margin-left: 6.25rem !important;
}

.m-26 {
  margin: 6.5rem !important;
}

.mt-26,
.my-26 {
  margin-top: 6.5rem !important;
}

.mr-26,
.mx-26 {
  margin-right: 6.5rem !important;
}

.mb-26,
.my-26 {
  margin-bottom: 6.5rem !important;
}

.ml-26,
.mx-26 {
  margin-left: 6.5rem !important;
}

.m-27 {
  margin: 6.75rem !important;
}

.mt-27,
.my-27 {
  margin-top: 6.75rem !important;
}

.mr-27,
.mx-27 {
  margin-right: 6.75rem !important;
}

.mb-27,
.my-27 {
  margin-bottom: 6.75rem !important;
}

.ml-27,
.mx-27 {
  margin-left: 6.75rem !important;
}

.m-38 {
  margin: 7rem !important;
}

.mt-38,
.my-38 {
  margin-top: 7rem !important;
}

.mr-38,
.mx-38 {
  margin-right: 7rem !important;
}

.mb-38,
.my-38 {
  margin-bottom: 7rem !important;
}

.ml-38,
.mx-38 {
  margin-left: 7rem !important;
}

.m-29 {
  margin: 7.25rem !important;
}

.mt-29,
.my-29 {
  margin-top: 7.25rem !important;
}

.mr-29,
.mx-29 {
  margin-right: 7.25rem !important;
}

.mb-29,
.my-29 {
  margin-bottom: 7.25rem !important;
}

.ml-29,
.mx-29 {
  margin-left: 7.25rem !important;
}

.m-30 {
  margin: 7.5rem !important;
}

.mt-30,
.my-30 {
  margin-top: 7.5rem !important;
}

.mr-30,
.mx-30 {
  margin-right: 7.5rem !important;
}

.mb-30,
.my-30 {
  margin-bottom: 7.5rem !important;
}

.ml-30,
.mx-30 {
  margin-left: 7.5rem !important;
}

.m-31 {
  margin: 7.75rem !important;
}

.mt-31,
.my-31 {
  margin-top: 7.75rem !important;
}

.mr-31,
.mx-31 {
  margin-right: 7.75rem !important;
}

.mb-31,
.my-31 {
  margin-bottom: 7.75rem !important;
}

.ml-31,
.mx-31 {
  margin-left: 7.75rem !important;
}

.m-32 {
  margin: 8rem !important;
}

.mt-32,
.my-32 {
  margin-top: 8rem !important;
}

.mr-32,
.mx-32 {
  margin-right: 8rem !important;
}

.mb-32,
.my-32 {
  margin-bottom: 8rem !important;
}

.ml-32,
.mx-32 {
  margin-left: 8rem !important;
}

.m-33 {
  margin: 8.25rem !important;
}

.mt-33,
.my-33 {
  margin-top: 8.25rem !important;
}

.mr-33,
.mx-33 {
  margin-right: 8.25rem !important;
}

.mb-33,
.my-33 {
  margin-bottom: 8.25rem !important;
}

.ml-33,
.mx-33 {
  margin-left: 8.25rem !important;
}

.m-34 {
  margin: 8.5rem !important;
}

.mt-34,
.my-34 {
  margin-top: 8.5rem !important;
}

.mr-34,
.mx-34 {
  margin-right: 8.5rem !important;
}

.mb-34,
.my-34 {
  margin-bottom: 8.5rem !important;
}

.ml-34,
.mx-34 {
  margin-left: 8.5rem !important;
}

.m-35 {
  margin: 8.75rem !important;
}

.mt-35,
.my-35 {
  margin-top: 8.75rem !important;
}

.mr-35,
.mx-35 {
  margin-right: 8.75rem !important;
}

.mb-35,
.my-35 {
  margin-bottom: 8.75rem !important;
}

.ml-35,
.mx-35 {
  margin-left: 8.75rem !important;
}

.m-36 {
  margin: 9rem !important;
}

.mt-36,
.my-36 {
  margin-top: 9rem !important;
}

.mr-36,
.mx-36 {
  margin-right: 9rem !important;
}

.mb-36,
.my-36 {
  margin-bottom: 9rem !important;
}

.ml-36,
.mx-36 {
  margin-left: 9rem !important;
}

.m-37 {
  margin: 9.25rem !important;
}

.mt-37,
.my-37 {
  margin-top: 9.25rem !important;
}

.mr-37,
.mx-37 {
  margin-right: 9.25rem !important;
}

.mb-37,
.my-37 {
  margin-bottom: 9.25rem !important;
}

.ml-37,
.mx-37 {
  margin-left: 9.25rem !important;
}

.m-48 {
  margin: 9.5rem !important;
}

.mt-48,
.my-48 {
  margin-top: 9.5rem !important;
}

.mr-48,
.mx-48 {
  margin-right: 9.5rem !important;
}

.mb-48,
.my-48 {
  margin-bottom: 9.5rem !important;
}

.ml-48,
.mx-48 {
  margin-left: 9.5rem !important;
}

.m-39 {
  margin: 9.75rem !important;
}

.mt-39,
.my-39 {
  margin-top: 9.75rem !important;
}

.mr-39,
.mx-39 {
  margin-right: 9.75rem !important;
}

.mb-39,
.my-39 {
  margin-bottom: 9.75rem !important;
}

.ml-39,
.mx-39 {
  margin-left: 9.75rem !important;
}

.m-40 {
  margin: 10rem !important;
}

.mt-40,
.my-40 {
  margin-top: 10rem !important;
}

.mr-40,
.mx-40 {
  margin-right: 10rem !important;
}

.mb-40,
.my-40 {
  margin-bottom: 10rem !important;
}

.ml-40,
.mx-40 {
  margin-left: 10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.pt-3,
.py-3 {
  padding-top: 0.75rem !important;
}

.pr-3,
.px-3 {
  padding-right: 0.75rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.75rem !important;
}

.pl-3,
.px-3 {
  padding-left: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.pt-5,
.py-5 {
  padding-top: 1.25rem !important;
}

.pr-5,
.px-5 {
  padding-right: 1.25rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 1.25rem !important;
}

.pl-5,
.px-5 {
  padding-left: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 1.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 1.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 1.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.pt-7,
.py-7 {
  padding-top: 1.75rem !important;
}

.pr-7,
.px-7 {
  padding-right: 1.75rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 1.75rem !important;
}

.pl-7,
.px-7 {
  padding-left: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.pt-8,
.py-8 {
  padding-top: 2rem !important;
}

.pr-8,
.px-8 {
  padding-right: 2rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 2rem !important;
}

.pl-8,
.px-8 {
  padding-left: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.pt-9,
.py-9 {
  padding-top: 2.25rem !important;
}

.pr-9,
.px-9 {
  padding-right: 2.25rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 2.25rem !important;
}

.pl-9,
.px-9 {
  padding-left: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 2.5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 2.5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 2.5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 2.5rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.pt-11,
.py-11 {
  padding-top: 2.75rem !important;
}

.pr-11,
.px-11 {
  padding-right: 2.75rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 2.75rem !important;
}

.pl-11,
.px-11 {
  padding-left: 2.75rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.pt-12,
.py-12 {
  padding-top: 3rem !important;
}

.pr-12,
.px-12 {
  padding-right: 3rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 3rem !important;
}

.pl-12,
.px-12 {
  padding-left: 3rem !important;
}

.p-13 {
  padding: 3.25rem !important;
}

.pt-13,
.py-13 {
  padding-top: 3.25rem !important;
}

.pr-13,
.px-13 {
  padding-right: 3.25rem !important;
}

.pb-13,
.py-13 {
  padding-bottom: 3.25rem !important;
}

.pl-13,
.px-13 {
  padding-left: 3.25rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.pt-14,
.py-14 {
  padding-top: 3.5rem !important;
}

.pr-14,
.px-14 {
  padding-right: 3.5rem !important;
}

.pb-14,
.py-14 {
  padding-bottom: 3.5rem !important;
}

.pl-14,
.px-14 {
  padding-left: 3.5rem !important;
}

.p-15 {
  padding: 3.75rem !important;
}

.pt-15,
.py-15 {
  padding-top: 3.75rem !important;
}

.pr-15,
.px-15 {
  padding-right: 3.75rem !important;
}

.pb-15,
.py-15 {
  padding-bottom: 3.75rem !important;
}

.pl-15,
.px-15 {
  padding-left: 3.75rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.pt-16,
.py-16 {
  padding-top: 4rem !important;
}

.pr-16,
.px-16 {
  padding-right: 4rem !important;
}

.pb-16,
.py-16 {
  padding-bottom: 4rem !important;
}

.pl-16,
.px-16 {
  padding-left: 4rem !important;
}

.p-17 {
  padding: 4.25rem !important;
}

.pt-17,
.py-17 {
  padding-top: 4.25rem !important;
}

.pr-17,
.px-17 {
  padding-right: 4.25rem !important;
}

.pb-17,
.py-17 {
  padding-bottom: 4.25rem !important;
}

.pl-17,
.px-17 {
  padding-left: 4.25rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.pt-18,
.py-18 {
  padding-top: 4.5rem !important;
}

.pr-18,
.px-18 {
  padding-right: 4.5rem !important;
}

.pb-18,
.py-18 {
  padding-bottom: 4.5rem !important;
}

.pl-18,
.px-18 {
  padding-left: 4.5rem !important;
}

.p-19 {
  padding: 4.75rem !important;
}

.pt-19,
.py-19 {
  padding-top: 4.75rem !important;
}

.pr-19,
.px-19 {
  padding-right: 4.75rem !important;
}

.pb-19,
.py-19 {
  padding-bottom: 4.75rem !important;
}

.pl-19,
.px-19 {
  padding-left: 4.75rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.pt-20,
.py-20 {
  padding-top: 5rem !important;
}

.pr-20,
.px-20 {
  padding-right: 5rem !important;
}

.pb-20,
.py-20 {
  padding-bottom: 5rem !important;
}

.pl-20,
.px-20 {
  padding-left: 5rem !important;
}

.p-21 {
  padding: 5.25rem !important;
}

.pt-21,
.py-21 {
  padding-top: 5.25rem !important;
}

.pr-21,
.px-21 {
  padding-right: 5.25rem !important;
}

.pb-21,
.py-21 {
  padding-bottom: 5.25rem !important;
}

.pl-21,
.px-21 {
  padding-left: 5.25rem !important;
}

.p-22 {
  padding: 5.5rem !important;
}

.pt-22,
.py-22 {
  padding-top: 5.5rem !important;
}

.pr-22,
.px-22 {
  padding-right: 5.5rem !important;
}

.pb-22,
.py-22 {
  padding-bottom: 5.5rem !important;
}

.pl-22,
.px-22 {
  padding-left: 5.5rem !important;
}

.p-23 {
  padding: 5.75rem !important;
}

.pt-23,
.py-23 {
  padding-top: 5.75rem !important;
}

.pr-23,
.px-23 {
  padding-right: 5.75rem !important;
}

.pb-23,
.py-23 {
  padding-bottom: 5.75rem !important;
}

.pl-23,
.px-23 {
  padding-left: 5.75rem !important;
}

.p-24 {
  padding: 6rem !important;
}

.pt-24,
.py-24 {
  padding-top: 6rem !important;
}

.pr-24,
.px-24 {
  padding-right: 6rem !important;
}

.pb-24,
.py-24 {
  padding-bottom: 6rem !important;
}

.pl-24,
.px-24 {
  padding-left: 6rem !important;
}

.p-25 {
  padding: 6.25rem !important;
}

.pt-25,
.py-25 {
  padding-top: 6.25rem !important;
}

.pr-25,
.px-25 {
  padding-right: 6.25rem !important;
}

.pb-25,
.py-25 {
  padding-bottom: 6.25rem !important;
}

.pl-25,
.px-25 {
  padding-left: 6.25rem !important;
}

.p-26 {
  padding: 6.5rem !important;
}

.pt-26,
.py-26 {
  padding-top: 6.5rem !important;
}

.pr-26,
.px-26 {
  padding-right: 6.5rem !important;
}

.pb-26,
.py-26 {
  padding-bottom: 6.5rem !important;
}

.pl-26,
.px-26 {
  padding-left: 6.5rem !important;
}

.p-27 {
  padding: 6.75rem !important;
}

.pt-27,
.py-27 {
  padding-top: 6.75rem !important;
}

.pr-27,
.px-27 {
  padding-right: 6.75rem !important;
}

.pb-27,
.py-27 {
  padding-bottom: 6.75rem !important;
}

.pl-27,
.px-27 {
  padding-left: 6.75rem !important;
}

.p-38 {
  padding: 7rem !important;
}

.pt-38,
.py-38 {
  padding-top: 7rem !important;
}

.pr-38,
.px-38 {
  padding-right: 7rem !important;
}

.pb-38,
.py-38 {
  padding-bottom: 7rem !important;
}

.pl-38,
.px-38 {
  padding-left: 7rem !important;
}

.p-29 {
  padding: 7.25rem !important;
}

.pt-29,
.py-29 {
  padding-top: 7.25rem !important;
}

.pr-29,
.px-29 {
  padding-right: 7.25rem !important;
}

.pb-29,
.py-29 {
  padding-bottom: 7.25rem !important;
}

.pl-29,
.px-29 {
  padding-left: 7.25rem !important;
}

.p-30 {
  padding: 7.5rem !important;
}

.pt-30,
.py-30 {
  padding-top: 7.5rem !important;
}

.pr-30,
.px-30 {
  padding-right: 7.5rem !important;
}

.pb-30,
.py-30 {
  padding-bottom: 7.5rem !important;
}

.pl-30,
.px-30 {
  padding-left: 7.5rem !important;
}

.p-31 {
  padding: 7.75rem !important;
}

.pt-31,
.py-31 {
  padding-top: 7.75rem !important;
}

.pr-31,
.px-31 {
  padding-right: 7.75rem !important;
}

.pb-31,
.py-31 {
  padding-bottom: 7.75rem !important;
}

.pl-31,
.px-31 {
  padding-left: 7.75rem !important;
}

.p-32 {
  padding: 8rem !important;
}

.pt-32,
.py-32 {
  padding-top: 8rem !important;
}

.pr-32,
.px-32 {
  padding-right: 8rem !important;
}

.pb-32,
.py-32 {
  padding-bottom: 8rem !important;
}

.pl-32,
.px-32 {
  padding-left: 8rem !important;
}

.p-33 {
  padding: 8.25rem !important;
}

.pt-33,
.py-33 {
  padding-top: 8.25rem !important;
}

.pr-33,
.px-33 {
  padding-right: 8.25rem !important;
}

.pb-33,
.py-33 {
  padding-bottom: 8.25rem !important;
}

.pl-33,
.px-33 {
  padding-left: 8.25rem !important;
}

.p-34 {
  padding: 8.5rem !important;
}

.pt-34,
.py-34 {
  padding-top: 8.5rem !important;
}

.pr-34,
.px-34 {
  padding-right: 8.5rem !important;
}

.pb-34,
.py-34 {
  padding-bottom: 8.5rem !important;
}

.pl-34,
.px-34 {
  padding-left: 8.5rem !important;
}

.p-35 {
  padding: 8.75rem !important;
}

.pt-35,
.py-35 {
  padding-top: 8.75rem !important;
}

.pr-35,
.px-35 {
  padding-right: 8.75rem !important;
}

.pb-35,
.py-35 {
  padding-bottom: 8.75rem !important;
}

.pl-35,
.px-35 {
  padding-left: 8.75rem !important;
}

.p-36 {
  padding: 9rem !important;
}

.pt-36,
.py-36 {
  padding-top: 9rem !important;
}

.pr-36,
.px-36 {
  padding-right: 9rem !important;
}

.pb-36,
.py-36 {
  padding-bottom: 9rem !important;
}

.pl-36,
.px-36 {
  padding-left: 9rem !important;
}

.p-37 {
  padding: 9.25rem !important;
}

.pt-37,
.py-37 {
  padding-top: 9.25rem !important;
}

.pr-37,
.px-37 {
  padding-right: 9.25rem !important;
}

.pb-37,
.py-37 {
  padding-bottom: 9.25rem !important;
}

.pl-37,
.px-37 {
  padding-left: 9.25rem !important;
}

.p-48 {
  padding: 9.5rem !important;
}

.pt-48,
.py-48 {
  padding-top: 9.5rem !important;
}

.pr-48,
.px-48 {
  padding-right: 9.5rem !important;
}

.pb-48,
.py-48 {
  padding-bottom: 9.5rem !important;
}

.pl-48,
.px-48 {
  padding-left: 9.5rem !important;
}

.p-39 {
  padding: 9.75rem !important;
}

.pt-39,
.py-39 {
  padding-top: 9.75rem !important;
}

.pr-39,
.px-39 {
  padding-right: 9.75rem !important;
}

.pb-39,
.py-39 {
  padding-bottom: 9.75rem !important;
}

.pl-39,
.px-39 {
  padding-left: 9.75rem !important;
}

.p-40 {
  padding: 10rem !important;
}

.pt-40,
.py-40 {
  padding-top: 10rem !important;
}

.pr-40,
.px-40 {
  padding-right: 10rem !important;
}

.pb-40,
.py-40 {
  padding-bottom: 10rem !important;
}

.pl-40,
.px-40 {
  padding-left: 10rem !important;
}