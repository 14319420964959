
$ss-white: #FFFFFF;
$ss-gray-10: #FAFAFA;
$ss-gray-20: #F5F5F5;
$ss-gray-30: #EEEEEE;
$ss-gray-40: #E0E0E0;
$ss-gray-50: #BDBDBD;
$ss-gray-60: #9E9E9E;
$ss-gray-70: #757575;
$ss-gray-80: #616161;
$ss-gray-90: #424242;
$ss-gray-100: #212121;
$ss-black: #000000;
$ss-black-1a: #1A1A1A;

$ss-sky-5: #F2FBFE;
$ss-sky-10: #E6F6FE;
$ss-sky-20: #CDEEFD;
$ss-sky-50: #03A9F4;

$ss-blue-5: #F2F8FF;
$ss-blue-10: #E5F0FF;
$ss-blue-20: #CCE2FF;
$ss-blue-50: #006DFF;

$ss-red-5: #FFF5F5;
$ss-red-10: #FFEBEA;
$ss-red-20: #FFD8D6;
$ss-red-50: #FF3B30;

$ss-green-5: #F5FCF7;
$ss-green-10: #EBF9EE;
$ss-green-20: #D6F4DE;
$ss-green-50: #34C759;
$ss-green-partner: #18A779;
$ss-green-partner-20: #89D0B9;

$ss-orange-5: #FFFAF2;
$ss-orange-10: #FFF4E5;
$ss-orange-20: #FFEACC;
$ss-orange-50: #FF9500;

$ss-yellow-5: #FFFCF2;
$ss-yellow-10: #FFFAE5;
$ss-yellow-20: #FFF5CC;
$ss-yellow-50: #FFCC00;

$ss-lilac-5: #FBF6FD;
$ss-lilac-10: #F7EEFC;
$ss-lilac-20: #EFDCF8;
$ss-lilac-50: #AF52DE;

$ss-pink-5: #FFF4F6;
$ss-pink-10: #FFEAEE;
$ss-pink-20: #FFD5DD;
$ss-pink-50: #FF2D55;

:export {
  colorWhite: $ss-white;
  colorGray50: $ss-gray-50;
  colorGray60: $ss-gray-60;
  colorGray70: $ss-gray-70;
  colorGray100: $ss-gray-100;
  colorBlue50: $ss-blue-50;
  colorRed50: $ss-red-50;
}