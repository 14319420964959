@import "normalize.css";
@import "NotoSansKR.css";
@import "reset-css";
@import "common";

* {
  font-family: NotoSansKR, serif !important;
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }

}

html {
  font-size: 4px;
}

input {
  box-sizing: border-box;
}

p {
  word-break: break-word;
}