.flex-box {
  display: flex;
  align-items: center;
}

.hide {
  display: none !important;
}

.scrollable-y {
  overflow-y: auto;
  height: 100vh;
}

.responsive-body {
    padding: 0 32px;
  }

@media (max-width: 1079px) {
  .mobile-layout {
    padding-left: 20px;
    padding-right: 20px;
  }
  .responsive-body {
    padding: 0 20px;
  }
}