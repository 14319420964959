@import "../base/typo";
@import "../base/utils";

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.h3 {
  @include h3;
}

.h4 {
  @include h4;
}

.h5 {
  @include h5;
}

.h6 {
  @include h6;
}

.h7 {
  @include h7;
}

.subtitle-text-1 {
  @include subtitle-text-1;
}

.subtitle-text-2 {
  @include subtitle-text-2;
}

.body-text-1 {
  @include body-text-1;
}

.body-text-2 {
  @include body-text-2;
}

.body-text-3 {
  @include body-text-3;
}

.body-text-4-m {
  @include body-text-4-m;
}

.content-text-1 {
  @include content-text-1;
}

.content-text-2 {
  @include content-text-2;
}

.btn-text-1 {
  @include btn-text-1;
}

.btn-text-2 {
  @include btn-text-2;
}

.btn-text-3 {
  @include btn-text-3;
}

.btn-text-4 {
  @include btn-text-4;
}

.btn-text-5 {
  @include btn-text-5;
}

.caption-text-1 {
  @include caption-text-1;
}

.caption-text-2 {
  @include caption-text-2;
}

.ss-white {
  color: $ss-white
}

.ss-gray-10 {
  color: $ss-gray-10
}

.ss-gray-20 {
  color: $ss-gray-20
}

.ss-gray-30 {
  color: $ss-gray-30
}

.ss-gray-40 {
  color: $ss-gray-40
}

.ss-gray-50 {
  color: $ss-gray-50
}

.ss-gray-60 {
  color: $ss-gray-60
}

.ss-gray-70 {
  color: $ss-gray-70
}

.ss-gray-80 {
  color: $ss-gray-80
}

.ss-gray-90 {
  color: $ss-gray-90
}

.ss-gray-100 {
  color: $ss-gray-100
}

.ss-black {
  color: $ss-black
}

.ss-sky-5 {
  color: $ss-sky-5
}

.ss-sky-10 {
  color: $ss-sky-10
}

.ss-sky-20 {
  color: $ss-sky-20
}

.ss-sky-50 {
  color: $ss-sky-50
}

.ss-blue-5 {
  color: $ss-blue-5
}

.ss-blue-10 {
  color: $ss-blue-10
}

.ss-blue-20 {
  color: $ss-blue-20
}

.ss-blue-50 {
  color: $ss-blue-50
}

.ss-red-5 {
  color: $ss-red-5
}

.ss-red-10 {
  color: $ss-red-10
}

.ss-red-20 {
  color: $ss-red-20
}

.ss-red-50 {
  color: $ss-red-50
}

.ss-green-5 {
  color: $ss-green-5
}

.ss-green-10 {
  color: $ss-green-10
}

.ss-green-20 {
  color: $ss-green-20
}

.ss-green-50 {
  color: $ss-green-50
}
